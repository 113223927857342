.eyebrow {
  width: 100%;
  color: #fff;
  background-color: rgb(71, 71, 71);
  text-align: center;
  font-size: 12px;
  padding: 8px 0;
  line-height: 1.5;
  letter-spacing: 1px;
  z-index: 2;
  @media(min-width: $tablet) {
    letter-spacing: 2px;
    font-size: 16px;
  }
  a {
    color: #eee;
  }
  span {
    position: relative;
    top: 0;
    right: 20px;
    display: flex;
    align-items: center;
    height: 30px;
    justify-content: center;
    margin-top: 5px;
    @media(min-width: $tablet) {
      position: absolute;
      height: 100%;
      margin: 0;
    }
    a {
      height: 60%;
      margin-left: 20px;
      &:hover {
        svg path { fill: #999; }
      }
    }
    svg {
      height: 100%;
      path {
        fill: #ebebeb;
        transition: all .2s;
      }
    }
  }
}

section.section--home-hero {
  height: 400px;
  overflow: hidden;
  @media(min-width: $tablet) {
    height: auto;
    min-height: 340px;
  }
  @media(min-width: $desktop) {
    min-height: 38.8vw;
  }
  img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
