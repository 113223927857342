form.general-form {
  position: relative;
  display: block;
  width: 100%;
  box-shadow: 0px 0px 12px -4px #999;
  text-align: center;
  .intro {
    background: $green;
    padding: 10px;
    color: #fff;
    h2 {
      font-family: Baskerville, "Baskerville Old Face", "Hoefler Text", Garamond, "Times New Roman", serif;
      margin: 0;
      font-weight: 600;
    }
    p {
      margin: 0;
    }
  }
  .fieldset {
    margin: 0;
    padding: 20px;
    border: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  label.txt {
    width: calc(50% - 8px);
    margin-bottom: 16px;
    &.email {
      width: 100%;
    }
    input, textarea {
      display: block;
      background: rgba($green, 0.1);
      padding: 10px 8px;
      width: 100%;
      border: 1px solid transparent;
      transition: all .2s;
      outline: 2px solid transparent;
      outline-offset: -2px;
      font-size: 16px;
      font-family: "DIN", sans-serif;
      &:focus {
        border-color: $green;
        outline: rgba($green, 0.1) auto 2px;
      }
    }
  }
  label.sponsors {
    width: 100%;
    font-weight: 900;
    + span.radio.sponsor {
      width: 100%;
    }
  }
  span.radio {
    position: relative;
    display: block;
    width: calc(33% - 5px);
    margin-bottom: 10px;
    input[type="radio"] {
      height: 1px;
      width: 1px;
      top: 2px;
      background: none;
      border: none;
      position: absolute;
      overflow: hidden;
      -webkit-appearance: none;
      margin: 0;
      &:focus {
        outline: none;
      }
      &:checked + label {
        background: $green;
        color: #fff;
        border-color: $green;
        transform: translateY(-2px);
        box-shadow: 2px 0px 7px -4px #555;
        span.sub { color: #fff; }
      }
    }
    label {
      height: 100%;
      color: #555;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 5px;
      width: 100%;
      border: 1px solid #ebebeb;
      transition: all .2s;
      font-size: 14px;
      flex-wrap: wrap;
      text-transform: uppercase;
      letter-spacing: .02em;
      font-weight: 600;
      &:hover {
        cursor: pointer;
        border-color: $green;
        color: $green;
      }
      span.sub {
        display: block;
        width: 100%;
        font-weight: 300;
        font-style: italic;
        color: $accent;
      }
    }
  }
  input[type="submit"] {
    appearance: none;
    -webkit-appearance: none;
    background: $green;
    margin: 10px auto 0;
    color: #fff;
    font-size: 18px;
    padding: 10px 20px;
    border: 1px solid $green;
    letter-spacing: 2px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: Baskerville, sans-serif;
    transition: all .2s;
    &:hover {
      cursor: pointer;
      background-color: lighten($green, 5%);
    }
  }
  label.txtarea {
    margin-top: 5px;
    width: 100%;
  }
}
