@font-face {
	font-family: 'DIN';
	src: url('../fonts/D-DINCondensed.otf') format('opentype');
	font-weight: 200;
	font-style: normal;
}
@font-face {
	font-family: 'DIN';
	src: url('../fonts/D-DINCondensed-Bold.otf') format('opentype');
	font-weight: 600;
}

$tablet: 650px;
$desktop: 960px;
$desktopLarge: 1240px;
$green: #016E56;
$black: #333;
$accent: #7A8144;
