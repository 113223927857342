main.main {
  background: #fff;
}
section.section--main-content {
  padding: 30px 0;
  display: block;
  @media(min-width:$tablet) {
    padding: 100px 0;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    @media(min-width: $desktop) {
      padding: 0 35px;
      flex-direction: row;
    }
  }

  .main-content-container {
    width: 100%;
    @media(min-width: $desktop) {
      padding: 0 24px;
      width: 58%;
    }
  }
  .sidebar-container {
    width: 100%;
    @media(min-width: $desktop) {
      padding: 0 24px;
      width: 42%;
    }
  }
}

.main-content-container .content-section {
  display: flex;
  margin-bottom: 30px;
  .title {
    text-align: right;
    padding-right: 15px;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .05em;
    width: 20%;
    min-width: 80px;
    border-right: 2px solid #A7A9AD;
    line-height: 1.5;
    font-family: Baskerville, "Baskerville Old Face", "Hoefler Text", Garamond, "Times New Roman", serif;
    @media(min-width: $tablet) {
      font-size: 20px;
      min-width: 115px;
      padding-right: 30px;
    }
  }
  .body {
    padding-left: 15px;
    width: 80%;
    font-size: 16px;
    color: $accent;
    @media(min-width: $tablet) {
      padding-left: 30px;
      font-size: 18px;
    }
  }
}
