* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}
body, html {
  padding: 0;
  margin: 0;
  font-family: "DIN", sans-serif;
  font-weight: 300;
  font-size: 18px;
  letter-spacing: .02em;
  color: $black;
}
h1 {
  margin-top: 0px;
}
h2 {
  font-weight: normal;
  font-size: 20px;
  line-height: 1.25;
  margin: 0 0 24px 0;
  letter-spacing: 1px;
  @media(min-width: $desktop) {
    font-size: 22px;
  }
}
p {
  line-height: 1.8;
}
.wrapper {
  max-width: 960px;
  margin: 0 auto;
  padding: 0 20px;
  @media(min-width: $desktop) {
    max-width: 1048px;

  }
}
main {
  position: relative;
  width: 100%;
}
section {
  position: relative;
}

.parallax {
  position: absolute!important;
  display: block;
}

a {
  color: $black;
}
